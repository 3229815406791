import s3, { bucketName } from 'config/s3Config'

export async function generatePresignedUrl(s3Key, expirationInSeconds = 3600) {
  const params = {
    Bucket: bucketName,
    Key: s3Key,
    Expires: expirationInSeconds
  }
  try {
    const url = await s3.getSignedUrlPromise('getObject', params)

    return url
  } catch (error) {
    console.error('Error generating presigned URL:', error)
    throw error
  }
}
export async function getObjectURL(key) {
  const url = `https://${bucketName}.s3.amazonaws.com/${key}`
  return url
}

export async function uploadTos3(file, key) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file
    }

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Error uploading to S3:', err)
        reject(err) // Reject the Promise if there's an error
      } else {
        console.log('Uploaded to s3', data)
        resolve(data) // Resolve the Promise with the uploaded data
      }
    })
  })
}

export async function deleteFroms3(key) {
  const params = {
    Bucket: bucketName,
    Key: key
  }
  s3.deleteObject(params, (err, data) => {
    if (err) {
      console.error('Error uploading to S3:', err)
    } else {
      return data
    }
  })
}
