import React, { useEffect, useState } from 'react'
import Layout from 'view/layout/Header'
import useProjectList from 'components/customhook'
import './index.scss'
import { generatePresignedUrl } from 'helper/s3Helper'
import { getUserIdFromLocalStorage } from 'config/apiConfig'
import { handleLaunchEditor } from 'common/helpers'

const MyProjects = () => {
  const [thumbnails, setThumbnails] = useState([])

  useEffect(() => {
    document.title = 'My Projects'
  }, [])
  const projectList = useProjectList()

  useEffect(() => {
    const fetchData = async () => {
      const thumbnailPromises = projectList.map(async (project) => {
        const url = await getThumbnail(project)
        return url
      })

      // Wait for all promises to resolve
      const thumbnailUrls = await Promise.all(thumbnailPromises)

      setThumbnails(thumbnailUrls)
    }

    fetchData()
  }, [projectList]) // Empty dependency array to run the effect only once on mount

  const getThumbnail = async (project) => {
    const user_id = getUserIdFromLocalStorage()
    const s3Key = `${user_id}/projects/project${project.project_id}/${project.project_thumbnail_url}`
    const presignedUrl = await generatePresignedUrl(s3Key)
    return presignedUrl
  }

  return (
    <div className='container-div'>
      <Layout projectList={projectList} />
      <div className='container-myprojects'>
        <div className='myprojects-section'>
          {projectList?.length === 0 ? (
            <p className='message'>No saved projects found.</p>
          ) : (
            projectList.map((project, index) => {
              return (
                <div key={project.project_id} className='myproj-cards'>
                  <div className='proj-img-card'>
                    <img src={thumbnails[index]} alt='' />
                  </div>
                  <div className='proj-card-info'>
                    <h2 className='project-title'>{project.project_name}</h2>
                    <p className='updates'>Last updated: {project.last_updated}</p>
                    <div className='proj-access'>
                      <button onClick={() => handleLaunchEditor(project.project_id)} className='launch-btn'>
                        Launch Editor
                      </button>
                      <button className='dwn-btn'>Download</button>
                      <button className='dup-proj-btn'>Duplicate project</button>
                    </div>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default MyProjects
