import { useEffect, useState } from 'react'
import axios from 'axios'
import { getProjectList, getUserIdFromLocalStorage, headers } from 'config/apiConfig'

const useProjectList = () => {
  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        const user_id = getUserIdFromLocalStorage()

        const apiUrl = getProjectList(user_id)
        const response = await axios.get(apiUrl, { headers })

        const formattedProjects = response.data.map((project) => ({
          ...project,
          last_updated: new Date(project.last_updated).toLocaleDateString()
        }))

        setProjectList(formattedProjects)
      } catch (error) {
        // console.error('Error fetching project list:', error)
      }
    }

    fetchProjectList()
  }, [])

  return projectList
}

export default useProjectList
