// Add all the common helper related code to this file which can be reused across all other files
export const handleLaunchEditor = (project_id) => {
  const isLocalhost = window.location.hostname === 'localhost'
  const userID = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('user_role')

  // Define the redirect URL based on whether it's localhost or not
  const redirectUrl = isLocalhost ? process.env.REACT_APP_EDITOR_LOCAL_URL : process.env.REACT_APP_EDITOR_SERVER_URL

  let finalUrl

  if (project_id && (typeof project_id === 'number' || typeof project_id === 'string')) {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&project_id=${project_id}&user_role=${userRole}`
  } else {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&user_role=${userRole}`
  }
  // Open the URL in the same tab
  window.location.href = finalUrl
}

export const handleLaunchEditorBaseTemplate = (baseTemplateID) => {
  const isLocalhost = window.location.hostname === 'localhost'
  const userID = localStorage.getItem('user_id')
  const token = localStorage.getItem('token')
  const userRole = localStorage.getItem('user_role')

  // Define the redirect URL based on whether it's localhost or not
  const redirectUrl = isLocalhost ? process.env.REACT_APP_EDITOR_LOCAL_URL : process.env.REACT_APP_EDITOR_SERVER_URL

  let finalUrl

  if (userID && token && isEditor() && baseTemplateID) {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&baseTemplateID=${baseTemplateID}&user_role=${userRole}`
    // Open the URL in the same tab
    window.location.href = finalUrl
  } else {
    finalUrl = `${redirectUrl}?user_id=${userID}&token=${token}&user_role=${userRole}&fromExploreTemplates=${baseTemplateID}`
    // Open the URL in the same tab
    window.location.href = finalUrl
  }
}

export function setCookie(name, value, days) {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + days * 24 * 60 * 60 * 1000)

  const expires = `expires=${expirationDate.toUTCString()}`
  document.cookie = `${name}=${value}; ${expires}; path=/`
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export function getCookie(name) {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }

  return null
}

export function isEditor() {
  return localStorage.getItem('user_role') != 3
}
