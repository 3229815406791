import React, { useEffect, useState } from 'react'
import pluslogo from '../../assets/Pluslogo.svg'
import Layout from 'view/layout/Header'
import { useNavigate } from 'react-router'
import useProjectList from 'components/customhook'
import './index.scss'
import { handleLaunchEditor } from 'common/helpers'

const DashBoard = () => {
  const projectList = useProjectList()
  const [infoPopup, setInfoPopup] = useState(false)
  let navigate = useNavigate()
  useEffect(() => {
    document.title = 'Dashboard'
  }, [])
  const handleMousehover = () => setInfoPopup(true)
  const handleMouseout = () => setInfoPopup(false)

  const handleNavigate = () => {
    if (projectList.length > 0) {
      navigate('/myprojects')
    } else {
      handleLaunchEditor()
    }
  }

  return (
    <div className='container-div'>
      <Layout mousehover={handleMousehover} mouseout={handleMouseout} projectList={projectList} />
      <div className='center'>
        <div onClick={handleLaunchEditor} className='square'>
          <img src={pluslogo} alt='pluslogo' className='plus_icon' />
        </div>
        <p className='text'>Create a New Project</p>
        {infoPopup && projectList.length === 0 && (
          <div className='hover-block' onMouseOut={handleMouseout} onMouseOver={handleMousehover}>
            You don&apos;t have any saved projects. <span onClick={handleNavigate}>Click here</span> to start a new project
          </div>
        )}
      </div>
    </div>
  )
}

export default DashBoard
